@charset "UTF-8";
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––––––

ここから　「　f_pt01　」　用css

–––––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
footer {
  background-color: #eeeef6; }
  footer .wrap_footer .inner_box {
    padding: 30px 20px; }
    footer .wrap_footer .inner_box .inner_logo {
      margin-bottom: 25px; }
      footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo {
        display: block;
        margin: 0 auto;
        width: 200px; }
        footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo img {
          vertical-align: middle; }
    footer .wrap_footer .inner_box .inner_address {
      text-align: center;
      font-size: 1.3rem; }
      footer .wrap_footer .inner_box .inner_address .block_zip .item_title {
        display: block;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 15px; }

@media only screen and (min-width: 767px) {
  footer .wrap_menu {
    background: #5092e0; }
    footer .wrap_menu .inner_menu {
      margin: 0 auto;
      max-width: 1080px;
      padding: 8px 20px; }
      footer .wrap_menu .inner_menu ul {
        display: flex;
        flex-flow: wrap row;
        justify-content: center; }
        footer .wrap_menu .inner_menu ul li a {
          color: white;
          display: block;
          padding: 5px 30px 5px 0; }
  footer .wrap_footer .inner_box .inner_logo {
    margin-bottom: 20px; }
    footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo {
      display: block;
      margin: 0 auto; }
      footer .wrap_footer .inner_box .inner_logo .block_logo .item_logo img {
        height: 30px;
        width: 250px; }
  footer .wrap_footer .inner_box .inner_address {
    font-size: 1.4rem; }
    footer .wrap_footer .inner_box .inner_address .block_zip .item_title {
      margin-bottom: 15px;
      font-size: 1.6rem; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#copyright {
  background-color: #222222;
  padding: 8px;
  text-align: center; }
  #copyright a {
    color: white;
    display: block;
    font-size: 1.2rem; }

#js_topBtn {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 0;
  transition: all 0.3s ease;
  z-index: 98; }
  #js_topBtn .item_btn {
    background-color: #222222;
    display: block;
    height: 48px;
    position: relative;
    width: 48px; }
    #js_topBtn .item_btn .icon {
      color: white;
      display: block;
      text-align: center;
      line-height: 30px; }
      #js_topBtn .item_btn .icon img {
        height: 11px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 20px; }

@media only screen and (min-width: 767px) {
  #copyright {
    padding: 10px; } }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––––––

ここから　「　f_pt02　」　用css(使わないなら消してね)

–––––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
