@charset "utf-8";

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––––––

ここから　「　f_pt01　」　用css

–––––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
footer{
	background-color:#eeeef6;
	.wrap_footer{
		.inner_box{
			padding:30px 20px;
			.inner_logo{
				margin-bottom: 25px;
				.block_logo{
					.item_logo{
						display:block;
						margin:0 auto;
						width:200px;
						img{
							vertical-align: middle;
						}
					}
				}
			}
			.inner_address{
				text-align:center;
				font-size:1.3rem;
				.block_zip{
					.item_title{
						display:block;
						font-size:1.5rem;
						font-weight:600;
						margin-bottom:15px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 767px) {
	footer{
		.wrap_menu{
			background:#5092e0;
			.inner_menu{
				margin: 0 auto;
				max-width: 1080px;
				padding: 8px 20px;
				ul{
					display:flex;
					flex-flow:wrap row;
				    justify-content: center;
					li{
						a{
							color:white;
							display:block;
						    padding: 5px 30px 5px 0;
						}
					}
				}
			}
		}
		.wrap_footer{
			.inner_box{
				.inner_logo{
					margin-bottom:20px;
					.block_logo{
						.item_logo{
							display:block;
							margin:0 auto;
							img{
								height:30px;
								width:250px;
							}
						}
					}
				}
				.inner_address{
					font-size:1.4rem;
					.block_zip{
						.item_title{
							margin-bottom: 15px;
							font-size:1.6rem;
						}
					}
				}
			}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#copyright{
	background-color: #222222;
	padding: 8px;
	text-align:center;
	a{
		color: white;
		display:block;
		font-size:1.2rem;
	}
}
#js_topBtn{
	display:none;
	position:fixed;
	bottom:50px;
	right:0;
	transition: all 0.3s ease;
	z-index: 98;
	.item_btn{
		background-color:#222222;
		display:block;
		height:48px;
		position:relative;
		width:48px;
		.icon{
			color:white;
			display: block;
			text-align: center;
			line-height: 30px;
		    img{
		    	height:11px;
				position: absolute;
			 	top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
			 	-ms-transform: translate(-50%, -50%);
			 	transform: translate(-50%, -50%);
		    	width:20px;
		    }
	   	}
	}
}
@media only screen and (min-width: 767px) {
	#copyright{
		padding: 10px;
		a{
		}
	}
	#js_topBtn{
		.btn{
			.icon{
		   	}
		}
	}
}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––––––

ここから　「　f_pt02　」　用css(使わないなら消してね)

–––––––––––––––––––––––––––––––––––––––––––––––––––––
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
フッター
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// footer{
// 	.wrap_access{
// 		margin-bottom:50px;
// 		.inner_address{
// 			.block_title{
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				margin-bottom:10px;
// 				text-align:center;
// 				.icon{
// 					background-color:#44af35;
// 					border-radius:100%;
// 					color:white;
// 					display:block;
// 					font-size:1.5rem;
// 					height:28px;
// 					line-height: 28px;
// 					margin-right:10px;
// 					width:28px;
// 				}
// 				.item_title{
// 					color:#44af35;
// 					font-size:1.6rem;
// 					font-weight:600;
// 				}
// 			}
// 			.block_zip{
// 				margin-bottom:20px;
// 				text-align:center;
// 				span{
// 					display:block;
// 					&.item_zip01{
// 						font-size:1.4rem;
// 					}
// 					&.item_zip02{
// 						font-size:1.2rem;
// 					}
// 				}
// 			}
// 		}
// 		.inner_map{
// 			text-align:center;
// 			iframe{
// 				height:360px;
// 				margin-bottom:20px;
// 			}
// 			.block_btn{
// 				background-color:#44af35;
// 				border-radius:100px;
// 				color:white;
// 				display:inline-block;
// 				font-size:1.6rem;
// 				font-weight:600;
// 				padding: 9px;
// 				width:75%;
// 			}
// 		}
// 	}
// 	.wrap_menu{
// 		.inner_title{
// 			color:#009844;
// 			font-size:1.8rem;
// 			font-weight:600;
// 			margin-bottom:20px;
// 			text-align:center;
// 		}
// 		.inner_menu{
// 			ul{

// 				li{
// 					text-align:center;
// 					a{
// 						background-color:#ccc;
// 						border-radius:100px;
// 						color:white;
// 						display:block;
// 						font-size:1.8rem;
// 						font-weight:600;
// 						margin: 0 auto 20px;
// 						padding: 7px;
// 						width:75%;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// @media only screen and (min-width: 767px) {
// 	footer{
// 		.wrap_access{
// 			margin-bottom:30px;
// 			.inner_address{
// 				.block_title{
// 					.icon{
// 						font-size:1.8rem;
// 						height:34px;
// 						line-height: 34px;
// 						width:34px;
// 					}
// 					.item_title{
// 						font-size:1.8rem;
// 					}
// 				}
// 				.block_zip{
// 					margin-bottom:20px;
// 					text-align:center;
// 					span{
// 						&.item_zip01{
// 						}
// 						&.item_zip01{
// 						}
// 					}
// 				}
// 			}
// 			.inner_map{
// 				text-align:center;
// 				iframe{
// 					margin-bottom:0px;
// 				}
// 				.block_btn{
// 					display:none;
// 				}
// 			}
// 		}
// 		.wrap_menu{
// 		    max-width: 1020px;
// 		    margin:0 auto;
// 		    padding: 0px 20px 15px;
// 			.inner_title{
// 				margin-bottom:25px;
// 			}
// 			.inner_menu{
// 				ul{
// 					display:flex;
// 				    justify-content: space-between;
// 					li{
// 						text-align:center;
// 						a{
// 							margin: 0 0 15px;
// 							padding: 7px 40px;
// 							width:inherit;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
コピーライト、ページトップへ戻る
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// #copyright{
// 	padding: 8px;
// 	text-align:center;
// 	a{
// 		display:inline-block;
// 		font-size:1.4rem;
// 	}
// }
// #js_topBtn{
// 	display:none;
// 	position:fixed;
// 	bottom:50px;
// 	right:0;
// 	transition: all 0.3s ease;
// 	z-index: 99;
// 	.item_btn{
// 		background-color:#009844;
// 	    border-radius: 100%;
// 		display:block;
// 		height:50px;
// 		position:relative;
// 		width:50px;
// 		.icon{
// 			color:white;
// 		    position: absolute;
// 		    top: 50%;
// 		    left: 0;
// 		    right: 0;
// 		    text-align: center;
// 		    -webkit-transform: translateY(-50%);
// 		    transform: translateY(-50%);
// 	   	}
// 	}
// }
// @media only screen and (min-width: 767px) {
// 	#copyright{
// 		padding: 10px;
// 		a{
// 			font-size:1.2rem;
// 		}
// 	}
// 	#js_topBtn{
// 		.btn{
// 			.icon{
// 		   	}
// 		}
// 	}
// }